var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fretboard",style:(_vm.fretboardStyle)},_vm._l((_vm.instrument.strings),function(string,stringIndex){return _c('div',{key:stringIndex,staticClass:"string"},_vm._l((_vm.instrument.numberOfFrets + 1),function(_,fretIndex){return _c('div',{key:fretIndex,staticClass:"string-fret",class:{
				'single-fretmark':
					stringIndex === 0 && _vm.instrument.fretMarks[fretIndex] === 'single',
			}},[(_vm.getBubbleText(stringIndex, fretIndex))?_c('span',{staticClass:"bubble",style:({
					color: _vm.getBubbleTextColor(stringIndex, fretIndex),
					backgroundColor: _vm.getBubbleColor(stringIndex, fretIndex),
				})},[_vm._v(" "+_vm._s(_vm.getBubbleText(stringIndex, fretIndex))+" ")]):_vm._e(),(
					stringIndex === 0 && _vm.instrument.fretMarks[fretIndex] === 'double'
				)?_c('div',{staticClass:"double-fretmark"}):_vm._e()])}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }